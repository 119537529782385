import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import _ from 'lodash'
import styled from 'styled-components'

// Components
import {
  TitleDefault,
  TitleSubDefault,
  ContentDefault,
} from 'components/Elements'
import SidebarMenu from 'components/Sidebar/SidebarMenu'
import Sidebar from 'components/Sidebar'
import Layout from 'components/Layout'

import '../styles/templates/ServiceProducts.scss'

const CustomImage = styled(BackgroundImage)`
  width: 100%;
  height: 150px;
`

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    serviceproduct: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      wordpress_id
      acf {
        header_image_products {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        content_text
        products {
          title_detailservice
          text_detailservice
          image_detailservice {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const ServiceProduct = ({
  data: {
    serviceproduct: {
      title,
      wordpress_id,
      acf: { header_image_products, content_text, products },
    },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) => {
  return (
    <Layout>
      <div className="serviceproduct-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  {title}
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={header_image_products.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </section>
        <section className="serviceproduct-content">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-3 pl-lg-0 pr-lg-5">
                <SidebarMenu currentPageID={wordpress_id} />
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-8 pr-lg-5">
                    <TitleDefault className="mb-lg-4">{title}</TitleDefault>
                    <ContentDefault>
                      <div dangerouslySetInnerHTML={{ __html: content_text }} />
                    </ContentDefault>
                  </div>
                  <div className="col-lg-4 pr-lg-0">
                    <Sidebar />
                  </div>
                </div>
                <div className="row mt-5 pt-5">
                  {_.map(products, (product, index) => (
                    <div key={index} className="col-lg-4 mb-5 d-flex flex-column">
                      {product.image_detailservice ? (
                        <CustomImage
                          style={{ backgroundSize: 'contain' }}
                          fluid={
                            product.image_detailservice.localFile
                              .childImageSharp.fluid
                          }
                          className="pb-3"
                        />
                      ) : (
                        <div> </div>
                      )}
                      <TitleSubDefault className="mb-3 mt-3 pt-3">
                        {product.title_detailservice}
                      </TitleSubDefault>
                      <ContentDefault>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.text_detailservice,
                          }}
                        />
                      </ContentDefault>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServiceProduct
